import Api from '../../api';

class CalculatorService {
  getInfo = async () => {
    const res = await Api.Calculator.getInfo();

    return res.map(item => ({
      providerData: {
        provider: item.ProviderName,
        officeId: item.OfficeId,
      },
      hiddenFeeData: {
        forTicket: item.BaseByTrip,
        forSegment: item.BaseBySegment,
        percent: item.BasePercent,
      },
      additionalFeeData: {
        forTicket: item.TaxByTrip,
        forSegment: item.TaxBySegment,
        percent: item.TaxPercent,
      },
    }));
  }

  saveInfo = (item) => {
    const { providerData, hiddenFeeData, additionalFeeData } = item;
    const { provider, officeId } = providerData;

    const itemForBack = {
      ProviderName: provider,
      OfficeId: officeId,
      BaseByTrip: hiddenFeeData.forTicket,
      BaseBySegment: hiddenFeeData.forSegment,
      BasePercent: hiddenFeeData.percent,
      TaxByTrip: additionalFeeData.forTicket,
      TaxBySegment: additionalFeeData.forSegment,
      TaxPercent: additionalFeeData.percent,
    };

    return Api.Calculator.saveInfo(itemForBack);
  }

  deleteInfo = (item) => {
    const { providerData } = item;
    const { provider, officeId } = providerData;

    const itemForBack = {
      ProviderName: provider,
      OfficeId: officeId,
      BaseByTrip: null,
      BaseBySegment: null,
      BasePercent: null,
      TaxByTrip: null,
      TaxBySegment: null,
      TaxPercent: null,
    };

    return Api.Calculator.saveInfo(itemForBack);
  }
}

export default CalculatorService;
