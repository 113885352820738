import ACTIONS from '../../actions';
import Api from '../../api';
import { sortByTime } from '../../utils/calls';

let store = null;


class CallsService {
  constructor() {
    store = this.getStore('Calls');
  }
  async initCalls() {
    try {
      const res = await Api.Calls.getCalls();
      const sortedCalls = sortByTime(res);
      store.dispatch({
        type: ACTIONS.CALLS.INIT,
        calls: sortedCalls,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.CALLS.INIT,
        calls: [],
      });
    }
  }

  async updateCalls() {
    const { calls } = this.get();
    store.dispatch({ type: ACTIONS.CALLS.UPDATE, isRequesting: true, calls });
    try {
      const res = await Api.Calls.getCalls();
      const sortedCalls = sortByTime(res);
      store.dispatch({
        type: ACTIONS.CALLS.UPDATE,
        calls: sortedCalls,
        isRequesting: false,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.CALLS.UPDATE,
        calls: [],
        isRequesting: false,
      });
    }
  }

  get = () => store.getState();

  subscribe = callback => store.subscribe(callback);
}

export default CallsService;
