import ACTIONS from '../../actions';
import Api from '../../api';

let store = null;

class CompaniesService {
  constructor() {
    store = this.getStore('Companies');
  }

  searchVacationAccounts = async (value) => {
    const params = {
      query: value.trim(),
    };

    try {
      const res = await Api.Vacation.findAccounts(params);
      return store.dispatch({
        type: ACTIONS.COMPANIES.UPDATE,
        res,
      });
    } catch (e) {
      //
    }
  }

  searchAccounts = (value, isInnOnly) => {
    const params = {
      query: value.trim(),
      isInnOnly,
    };

    return Api.Companies.searchAccounts(params).then(res => store.dispatch({
      type: ACTIONS.COMPANIES.UPDATE,
      res,
    }));
  };

  searchOrders = (value, active) => Api.Companies.searchOrders(value, active).then((res) => {
    store.dispatch({
      type: ACTIONS.COMPANIES.UPDATEORDERS,
      res,
    });
  });

  get = () => store.getState();

  subscribe = callback => store.subscribe(callback);

  clearCacheCompanies = () => Api.Companies.clearCacheCompanies();
}

export default CompaniesService;
